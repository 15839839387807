.post-excerpt {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
  padding: 20px 30px;
  border-radius: var(--rounded);
  background: var(--background);
  text-align: left;
  box-shadow: var(--shadow);
}

.post-excerpt > * {
  margin: 0 !important;
 
}

.post-excerpt > a:first-child {
  width: 100%;
  font-weight: var(--semi-bold);
  font-size: 20px;
}


.post-excerpt > div {
  justify-content: flex-start;
}

.post-text {
  width: 100%;
  line-height: 10px;

}

.post-image {
  display: flex;
  width: 160px;
  height: 120px;
  margin-right: 20px;
  margin-top: 10px;
  border-radius: 10px;
}

.post-image img {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
}
  

.post-content{
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;

}


.read-more-link {
  color: rgb(57, 143, 171);
}